//function to resize container  
	var resizecontainerHoraire = function(){
		numberOfCol = $('.js-colDateChoix').length;


		if(!$('.isXS').is(":visible") && !$('.isSM').is(":visible")){
			if(numberOfCol==3){
				$('.js-containerCalendar').css('width' , '25%');
				$('.js-containerDates').css('width' , '75%');
			}else{
				$('.js-containerCalendar').css('width' , '50%');
				$('.js-containerDates').css('width' , '50%');
			}

		}; 

	}

// function to add leading zero
	var addLeadingZero = function(num) {
	    if (num < 10) {
	      return "0" + num;
	    } else {
	      return "" + num;
	    }
	}

//function to display date in the right panel
	var displayDate = function(day,month,year){
		$('.dateofday').html(day+' '+month+' '+year);
	}

	
	
//function to display events in the right panel
	var displayEvents = function(events, key){
		var innerHtml = '';
		startHtml = '<div class="colDateChoix js-colDateChoix">';
		endHtml = '</div>';
		var j=1;
		for(var i = 0; i<events.length; i++){
			innerHtml = innerHtml + '<div class="choix" data-id="'+events[i].id+'" data-rel="choix" data-hour="'+events[i].hour+'" data-date="'+key+'">\
										<strong class="heure">'+events[i].hour+'</strong>\
										<span><span class="places">'+events[i].places+'</span> places disponibles</span>\
									</div>';
			if(j==5){
				innerHtml= innerHtml + '</div><div class="colDateChoix js-colDateChoix">';
				j=0;
			}
			j++;
		}
		$('.js-insertedChoice').html(startHtml+innerHtml+endHtml);
		resizecontainerHoraire();
	}

// Just a quick function to reset calendar, data panel and form
	var clearStuff = function(){
		$('.js-insertedChoice, .dateofday').html('');
		clearForm();
	}

// Just a quick function to clear form
	var clearForm = function(){
		$('#dateReservation').val('');
		$('#hourReservation').val('');
		$('#idReservation').val('');
	}

$( document ).ready(function() {

	// FEED THE FORM WITH THE DATE CHOOSE
		$('.containerHoraire').on('click', '[data-rel="choix"]', function(){
			$('[data-rel="choix"]').removeClass('active');
			$(this).addClass('active');
			$('#dateReservation').val($(this).data('date'));
			$('#hourReservation').val($(this).data('hour'));
			$('#idReservation').val($(this).data('id'));
		});

	//quick form validation
		$("#formReservation").on("submit", function(e){
			$('.error').html('');
			$("#formReservation input.required").each(function(){
				var name = $(this).attr("name");

				if($(this).val() == ""){
					$('.error').show();
				    if(name == "dateReservation" || name == "hourReservation"){
				    	$(".error").append("Vous devez sélectionner une date dans le calendrier<br>");
				    }else{
				    	$(".error").append("Vous devez remplir le champ <strong>"+ name+ "</strong><br>");
				    }
				  
					e.preventDefault();
				}else{
				  return true; 
				}
			});
		});

	//function to resize container    
		resizecontainerHoraire();


		var cal;
		var translateMonths= ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']

	//start Calendar
		$(".responsive-calendar").responsiveCalendar({
			startFromSunday: true,
			translateMonths:translateMonths,
			events: myevents,
			onInit: function(){
				cal = this.$element.find('[data-group="days"]');
				setTimeout(function(){
					$(cal).find('.today').addClass('activate');
					today = $(cal).find('.today a');
					var thisDayEvent, key;
					key = today.data('year')+'-'+addLeadingZero( today.data('month') )+'-'+addLeadingZero( today.data('day') );
					thisDayEvent = myevents[key];
					displayDate(today.data('day'), translateMonths[today.data('month')-1], today.data('year'));
					displayEvents(thisDayEvent.dayEvents, key);
				},250);
			},
			onActiveDayClick: function(events) {
		      var thisDayEvent, key;
		      clearForm();
		      key = $(this).data('year')+'-'+addLeadingZero( $(this).data('month') )+'-'+addLeadingZero( $(this).data('day') );
		      $('.activate').removeClass('activate');
		      $(this).parent().addClass('activate')
		      thisDayEvent = events[key];
		      displayDate($(this).data('day'), translateMonths[$(this).data('month')-1], $(this).data('year'));
		      displayEvents(thisDayEvent.dayEvents, key);

		      //if mobile scroll to panel date
		      if($('.isXS').is(":visible")){
		      	$('html, body').animate({
	      	        scrollTop: $(".js-containerDates").offset().top
	      	    }, 1000);
		      }
		    },
		    onMonthChange: function(){
		    	clearStuff();
				resizecontainerHoraire();
		    }
		});

});